import { BaseService } from './BaseService';

class InstitutionService extends BaseService {
  getDetail(userGroupId) {
    return this.get(`${userGroupId}/detail`);
  }
  getExploreIndustryInterests() {
    return this.get(`explore/industry-interests`);
  }
  getExplorePopular(page, size) {
    return this.get(`explore/popular`, { params: { page, size } });
  }
  getExploreNewest(page, size) {
    return this.get(`explore/newest`, { params: { page, size } });
  }
  postSearch(page = 1, size = 10, data) {
    return this.post(`search`, data, { params: { page, size } });
  }
  getDetailQuestions(userGroupId) {
    return this.get(`${userGroupId}/detail/questions`);
  }
  postScheduleMeeting(userGroupId) {
    return this.post(`${userGroupId}/schedule-meeting`);
  }
}

export default new InstitutionService('institutions');
