'use client';

// react
import { useCallback, useState } from 'react';
// next
import Link from 'next/link';
import dynamic from 'next/dynamic';
// sections
import BaseSection from './BaseSection';
import HeroSliderSection from './HeroSliderSection';
import RandomInvestorsSection from './RandomInvestorsSection';
import StrategicPartners from './StrategicPartners';
// components
import { LoadingScreen } from '@/components/minimal';
const CardsSlider = dynamic(() => import('@/components/common/CardsSlider'));
import { AnnouncementCard, EventCard } from '@/components/common/cards';
// services
import { SliderDataDto } from '@/services/SharedService/dto';
import { RecentUserGroupAnnouncementsDataDto } from '@/services/UserGroupAnnouncementsService/dto';
import { ContentsService, SharedService, UserGroupAnnouncementsService } from '@/services';
// hooks
import { useFetch } from '@/hooks';
// @mui
import { Box, Stack, Typography, Button } from '@mui/material';
// constants
import { paths } from '@/routes/paths';
import { EventsType } from '@/types';

export default function HomeView() {
  const [latestEvents, setLatestEvents] = useState<EventsType[]>([]);
  const [programs, setPrograms] = useState<EventsType[]>([]);
  const [sliders, setSliders] = useState<SliderDataDto[]>([]);
  const [recentUserGroupAnnouncements, setRecentUserGroupAnnouncements] = useState<
    RecentUserGroupAnnouncementsDataDto[]
  >([]);

  const fetchData = useCallback(async () => {
    const [
      { success: latestEventsSuccess, data: latestEventsData = [] },
      { success: recentUserGroupAnnouncementsSuccess, data: recentUserGroupAnnouncementsData = [] },
      { success: slidersSuccess, data: slidersData = [] },
    ] = await Promise.all([
      ContentsService.getLatestEvents(),
      UserGroupAnnouncementsService.getRecentAnnouncements(),
      SharedService.getSliders(),
    ]);
    if (latestEventsSuccess) {
      if (latestEventsData?.length) {
        const firstEvent = latestEventsData.shift();
        latestEventsData.push(firstEvent);
      }
      setLatestEvents(latestEventsData.filter((item: EventsType) => !item.program));
      setPrograms(latestEventsData.filter((item: EventsType) => item.program));
    }

    if (recentUserGroupAnnouncementsSuccess) {
      setRecentUserGroupAnnouncements(recentUserGroupAnnouncementsData);
    }
    if (slidersSuccess) {
      setSliders(
        slidersData?.sort(
          ({ order: a }: { order: number }, { order: b }: { order: number }) => a - b
        )
      );
    }

    return true;
  }, []);

  const { loading } = useFetch(fetchData);

  return (
    <>
      {loading && <LoadingScreen />}
      <HeroSliderSection sliders={sliders} />
      {Boolean(recentUserGroupAnnouncements?.length) && (
        <BaseSection header="" backgroundColor="#F7F7F7" height="auto" sx={{ pt: 1 }}>
          <Box sx={{ my: 1 }}>
            <Typography variant="h4" textAlign="center">
              Announcements
            </Typography>
          </Box>
          <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="center" spacing={2}>
            {recentUserGroupAnnouncements?.map((announcement) => (
              <Box key={`announcement-card-${announcement?.id}`}>
                <AnnouncementCard announcement={announcement} />
              </Box>
            ))}
          </Stack>
          <Box sx={{ mt: 1, textAlign: 'center' }}>
            <Button size="large" component={Link} href={paths.announcements}>
              View More
            </Button>
          </Box>
        </BaseSection>
      )}
      <RandomInvestorsSection />
      {Boolean(latestEvents?.length) && (
        <BaseSection header="" backgroundColor="#F7F7F7" height="auto">
          <CardsSlider title={'Events'} cards={latestEvents} CardComponent={EventCard} />
        </BaseSection>
      )}
      {Boolean(programs?.length) && (
        <BaseSection header="" backgroundColor="#FFFFFF" height="auto">
          <CardsSlider
            backgroundColor="#FFFFFF"
            title={'Programs'}
            cards={programs}
            CardComponent={EventCard}
          />
        </BaseSection>
      )}
      <StrategicPartners />
    </>
  );
}
