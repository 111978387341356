// components
import { Image } from '@/components/common';
// @mui
import { Grid, Container, Box, Typography } from '@mui/material';
// hooks
import { useShared } from '@/hooks';

export default function StrategicPartners() {
  const { strategicPartners } = useShared(['strategicPartners']);

  return (
    <Container maxWidth="lg" sx={{ pt: 0 }}>
      {Boolean(strategicPartners?.length) && (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5" textAlign="center" sx={{ my: 1 }}>
              Our Partners
            </Typography>
          </Grid>
          {strategicPartners?.map(
            ({ photo, externalLink }) =>
              Boolean(photo) &&
              (externalLink ? (
                <Grid item xs={12} md={4} key={photo}>
                  <a href={externalLink} target="_blank" rel="noreferrer">
                    <Box sx={{ m: 2 }}>
                      <Image width={350} height={100} src={photo} alt="ScaleupStream" />
                    </Box>
                  </a>
                </Grid>
              ) : (
                <Grid item xs={12} md={4} key={photo}>
                  <Box sx={{ m: 2 }}>
                    <Image width={350} height={100} src={photo} alt="ScaleupStream" />
                  </Box>
                </Grid>
              ))
          )}
        </Grid>
      )}
    </Container>
  );
}
