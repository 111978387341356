// @mui
import {
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
  Avatar,
  Stack,
  CardMedia,
  CardActions,
  Rating,
} from '@mui/material';
import { AddAlarm as AddAlarmIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
// next
import Link from 'next/link';
// utils
import imageLoader from '@/utils/imageLoader';
import { formatDateTime } from '@/utils/formatTime';
// images
import { noImage } from '@/images';
// react
import { useState } from 'react';
// services
import { ContentsService } from '@/services';
// components
import Iconify from '@/components/minimal/iconify';
import { Image } from '@/components/common';
// hooks
import { useAuth, useModal, useResponsive } from '@/hooks';
// enums
import {
  PITCH_EVENT_TYPE,
  EVENT_PROGRESS,
  EVENT_REGISTERED,
  USER_CONTENT_STATUS,
  PAYMENT_TYPE,
  CONTENT_TYPE,
} from '@/enums';
// constants
import { paths } from '@/routes/paths';

//-----------------------------------------------------------------------

const getEventDetail = (event) => {
  switch (event.type) {
    case CONTENT_TYPE.MEETING:
      return event.meeting;
    case CONTENT_TYPE.TRAINING_SESSION:
      return event.trainingSession;
    case CONTENT_TYPE.PROGRAM:
      return event.program;
    case CONTENT_TYPE.PITCH_EVENT:
      return event.pitchEvent;
    case CONTENT_TYPE.PRODUCT:
      return event.product;
    default:
      return {};
  }
};

const getContentUrl = (type) => {
  switch (type) {
    case CONTENT_TYPE.PROGRAM:
      return paths.programs;
    case CONTENT_TYPE.SERVICE:
    case CONTENT_TYPE.ONE_ON_ONE:
      return paths.services;
    case CONTENT_TYPE.PRODUCT:
      return paths.products.root;
    default:
      return paths.events;
  }
};

//-----------------------------------------------------------------------
export default function EventCard({ card }) {
  const {
    id,
    photo,
    type,
    title,
    isPaid,
    price,
    registered: contentRegistered,
    owner,
    isEventStartActive,
    isEventJoinActive,
    isInvited,
    eventProgress,
    externalLink,
    userGroup,
    user: eventUser,
    ratingScore,
    totalRatingCount,
  } = card;

  const contentPrice = type === CONTENT_TYPE.PRODUCT ? card.subContents[0]?.price : price;

  const { user } = useAuth();
  const { title: investorName } = userGroup ?? { title: null };
  const {
    detail: { firstName, lastName, profilePhoto },
  } = eventUser ?? { detail: { firstName: null, lastName: null, profilePhoto: null } };
  const {
    login: { setOpen: setLoginModalOpen },
    payment: { setOpen: setPaymentModalOpen, setProps: setPaymentModalProps },
  } = useModal();

  const isMobile = useResponsive('down', 'md');

  const [buttonLoading, setButtonLoading] = useState(false);
  const [registered, setRegistered] = useState(contentRegistered);

  const details = getEventDetail(card);
  const startDate = details && details.startDate ? new Date(details.startDate) : null;

  const handleRegister = async () => {
    setButtonLoading(true);
    const { success, data = {} } = await ContentsService.postBuy(id);
    if (success) {
      if (data.clientSecret) {
        setPaymentModalProps({
          contentId: id,
          userGroupPlanId: null,
          secret: data.clientSecret,
          paymentType: PAYMENT_TYPE.CONTENT,
          onSuccess: async () => {
            setRegistered(USER_CONTENT_STATUS.ACTIVE);
          },
        });
        setPaymentModalOpen(true);
      } else {
        setRegistered(USER_CONTENT_STATUS.ACTIVE);
      }
    }
    setButtonLoading(false);
  };

  const handleLogin = () => {
    setLoginModalOpen(true);
  };

  const handleStart = async () => {
    setButtonLoading(true);
    const { success, ...response } = await ContentsService.getMeetingsStart(id);
    if (success) {
      window.open(response.data.startUrl, '_blank');
    }
    setButtonLoading(false);
  };

  const handleJoin = async () => {
    setButtonLoading(true);
    if (externalLink) {
      window.open(externalLink, '_blank');
    } else {
      const { success, ...response } = await ContentsService.getMeetingsJoin(id);
      if (success) {
        window.open(response.data.joinUrl, '_blank');
      }
    }
    setButtonLoading(false);
  };

  return (
    <Box
      paddingTop={'10px'}
      paddingLeft={'5px'}
      paddingRight={'8px'}
      paddingBottom={'35px'}
      height="100%"
      width={{ xs: '240px', md: '275px' }}
      mx="auto"
    >
      <Box sx={{ filter: ' drop-shadow(0px 10px rgba(0, 0, 0, 0.1))', height: '100%' }}>
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Link href={`${getContentUrl(type)}/${id}`} legacyBehavior passHref prefetch={false}>
            <a>
              <CardMedia>
                <Image
                  src={photo}
                  width={isMobile ? 227 : 262}
                  height={isMobile ? 119 : 137}
                  onError={function (event) {
                    event.target.src = noImage.src;
                  }}
                  alt="event image"
                  sx={{
                    '&:hover': { cursor: 'pointer' },
                  }}
                />
                {[
                  CONTENT_TYPE.PITCH_EVENT,
                  CONTENT_TYPE.ONE_ON_ONE,
                  CONTENT_TYPE.SERVICE,
                  CONTENT_TYPE.PROGRAM,
                  CONTENT_TYPE.TRAINING_SESSION,
                ].includes(type) && (
                  <Typography
                    sx={{
                      zIndex: 1,
                      backgroundColor: ' #23aacd',
                      color: '#ffffff',
                      textAlign: 'center',
                      fontWeight: '700',
                      fontSize: 12,
                    }}
                  >
                    {type === CONTENT_TYPE.PITCH_EVENT && 'Pitch'}
                    {type === CONTENT_TYPE.TRAINING_SESSION && 'Training'}
                    {type === CONTENT_TYPE.SERVICE && 'Service/Product'}
                    {type === CONTENT_TYPE.PROGRAM && 'Program'}
                    {type === CONTENT_TYPE.ONE_ON_ONE && 'One On One'}
                  </Typography>
                )}
              </CardMedia>

              <CardContent>
                <Grid container spacing={1}>
                  {startDate && (
                    <Grid item xs={12}>
                      <Typography className="eventCardTime" gutterBottom>
                        {formatDateTime(startDate)}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography className="eventCardHead">{title}</Typography>
                    {investorName && (
                      <Typography className="eventCardUsername">{investorName}</Typography>
                    )}
                    {ratingScore && totalRatingCount && (
                      <Stack direction="row" spacing={1}>
                        <Typography variant="subtitle2" color="primary.main">
                          {ratingScore}
                        </Typography>
                        <Rating readOnly value={ratingScore} precision={0.1} size="small" />
                        <Typography>
                          ({totalRatingCount} review{totalRatingCount > 1 ? 's' : ''})
                        </Typography>
                      </Stack>
                    )}
                    {firstName && lastName && (
                      <Stack
                        sx={{ my: 2, cursor: 'pointer' }}
                        direction="row"
                        alignItems="center"
                        spacing={1}
                      >
                        {profilePhoto ? (
                          <Avatar
                            alt={`${firstName}_${lastName}`}
                            src={imageLoader({ src: profilePhoto })}
                            sx={{ width: 24, height: 24 }}
                          />
                        ) : (
                          <Avatar>{firstName[0]}</Avatar>
                        )}
                        <Typography fontSize={12} color="gray">
                          {firstName} {lastName}
                        </Typography>
                      </Stack>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </a>
          </Link>

          <CardActions sx={{ mt: 'auto' }}>
            {![
              CONTENT_TYPE.PROGRAM,
              CONTENT_TYPE.SERVICE,
              CONTENT_TYPE.ONE_ON_ONE,
              CONTENT_TYPE.PRODUCT,
            ].includes(type) ? (
              <Stack
                margin={1}
                direction="row"
                alignItems={'center'}
                justifyContent={'flex-end'}
                width="100%"
              >
                {owner && (
                  <LoadingButton
                    startIcon={<Iconify icon="bi:play-circle-fill" className="eventCardIcon" />}
                    color="success"
                    loading={buttonLoading}
                    onClick={handleStart}
                    disabled={!isEventStartActive}
                  >
                    Start
                  </LoadingButton>
                )}
                {!owner && (
                  <>
                    {/* Past Event */}
                    {!isEventJoinActive && eventProgress === EVENT_PROGRESS.PAST && (
                      <Typography color={(theme) => theme.palette.grey[500]} fontWeight="bold">
                        Past Event
                      </Typography>
                    )}
                    {/* Happening Now */}
                    {(eventProgress === EVENT_PROGRESS.NOW ||
                      (isEventJoinActive && eventProgress !== EVENT_PROGRESS.PAST)) && (
                      <>
                        {registered === EVENT_REGISTERED.ACTIVE && (
                          <LoadingButton
                            startIcon={
                              <Iconify icon="bi:play-circle-fill" className="eventCardIcon" />
                            }
                            color="success"
                            loading={buttonLoading}
                            onClick={handleJoin}
                          >
                            Join
                          </LoadingButton>
                        )}

                        {registered !== EVENT_REGISTERED.ACTIVE &&
                          (!isInvited &&
                          type === CONTENT_TYPE.PITCH_EVENT &&
                          details.pitchEventType === PITCH_EVENT_TYPE.INVITE_ONLY ? (
                            registered !== EVENT_REGISTERED.PENDING ? (
                              <Stack
                                direction="column"
                                flexDirection="column"
                                alignItems="flex-end"
                              >
                                <Link
                                  prefetch={false}
                                  href={`${paths.events}/${id}`}
                                  legacyBehavior
                                >
                                  <Typography
                                    color="success.main"
                                    fontWeight="bold"
                                    variant="subtitle2"
                                  >
                                    Go to Details
                                  </Typography>
                                </Link>
                                <Typography
                                  color={(theme) => theme.palette.error.main}
                                  fontWeight="bold"
                                  variant="subtitle2"
                                >
                                  This event is invite-only.
                                </Typography>
                              </Stack>
                            ) : (
                              <Typography color="success.main" fontWeight="bold">
                                Pending
                              </Typography>
                            )
                          ) : (
                            <LoadingButton
                              startIcon={<AddAlarmIcon className="eventCardIcon" />}
                              color="success"
                              loading={buttonLoading}
                              onClick={user ? handleRegister : handleLogin}
                            >
                              <Typography color="success.main" fontWeight="bold">
                                {!isPaid && 'Register'}
                                {isPaid && `Register $${contentPrice}`}
                              </Typography>
                            </LoadingButton>
                          ))}
                      </>
                    )}
                    {/* Upcoming */}
                    {!isEventJoinActive && eventProgress === EVENT_PROGRESS.UPCOMING && (
                      <>
                        {registered === EVENT_REGISTERED.ACTIVE && (
                          <Typography color="success.main" fontWeight="bold">
                            Upcoming
                          </Typography>
                        )}
                        {registered !== EVENT_REGISTERED.ACTIVE &&
                          (!isInvited &&
                          type === CONTENT_TYPE.PITCH_EVENT &&
                          details.pitchEventType === PITCH_EVENT_TYPE.INVITE_ONLY ? (
                            registered !== EVENT_REGISTERED.PENDING ? (
                              <Stack
                                direction="column"
                                flexDirection="column"
                                alignItems="flex-end"
                              >
                                <Link
                                  prefetch={false}
                                  href={`${paths.events}/${id}`}
                                  legacyBehavior
                                >
                                  <Typography
                                    color="success.main"
                                    fontWeight="bold"
                                    variant="subtitle2"
                                  >
                                    Go to Details
                                  </Typography>
                                </Link>
                                <Typography
                                  color={(theme) => theme.palette.error.main}
                                  fontWeight="bold"
                                  variant="subtitle2"
                                >
                                  This event is invite-only.
                                </Typography>
                              </Stack>
                            ) : (
                              <Typography color="success.main" fontWeight="bold">
                                Pending
                              </Typography>
                            )
                          ) : (
                            <LoadingButton
                              startIcon={<AddAlarmIcon className="eventCardIcon" />}
                              color="success"
                              loading={buttonLoading}
                              onClick={user ? handleRegister : handleLogin}
                            >
                              <Typography color="success.main" fontWeight="bold">
                                {!isPaid && 'Register'}
                                {isPaid && `Register $${contentPrice}`}
                              </Typography>
                            </LoadingButton>
                          ))}
                      </>
                    )}
                  </>
                )}
              </Stack>
            ) : (
              <Stack
                margin={1}
                spacing={1}
                direction="row"
                alignItems={'center'}
                justifyContent={'flex-end'}
                width="100%"
              >
                {type !== CONTENT_TYPE.PRODUCT && <AddAlarmIcon className="eventCardIcon" />}
                <Typography color="success.main" textAlign="center">
                  {isPaid || type === CONTENT_TYPE.PRODUCT ? `$${contentPrice}` : 'Free'}
                </Typography>
              </Stack>
            )}
          </CardActions>
        </Card>
      </Box>
    </Box>
  );
}
