// react
import { useCallback, useState } from 'react';
// @mui
import { Box, Button, Container, Stack, Typography } from '@mui/material';
// components
import { Loading } from '@/components/common';
import { UserGroupCard } from '@/components/common/cards';
// hooks
import { useFetch } from '@/hooks';
// services
import { InvestorsService } from '@/services';
// enums
import { USER_GROUP_TYPE } from '@/enums';
// next
import Link from 'next/link';
// constants
import { paths } from '@/routes/paths';

export default function RandomInvestorsSection() {
  const [randomInvestors, setRandomInvestors] = useState([]);

  const fetchRandomInvestors = useCallback(async () => {
    const { success, data = [] } = await InvestorsService.getExploreRandomInvestors();
    if (success) setRandomInvestors(data);

    return true;
  }, []);

  const { loading } = useFetch(fetchRandomInvestors);

  return (
    <Container component="section" maxWidth="100%" sx={{ my: 10 }}>
      {loading && <Loading />}
      {!loading && (
        <>
          <Typography textAlign="center" variant="h4">
            Investors
          </Typography>
          <Stack
            alignItems="center"
            justifyContent="center"
            direction={{ md: 'row', xs: 'column' }}
            sx={{ width: '100%' }}
          >
            {randomInvestors?.map((investor) => (
              <UserGroupCard
                key={`random-investor-${investor?.id}`}
                header={investor?.title}
                text={investor?.description}
                image={investor?.bannerPhoto}
                footer={`${investor?.totalPitch} pitch application`}
                id={investor?.id}
                userGroup={investor}
                userGroupType={USER_GROUP_TYPE.INVESTOR}
                rating={investor?.ratingScore}
                ratingCount={investor?.totalRatingCount}
              />
            ))}
          </Stack>
          <Box sx={{ mt: 1, textAlign: 'center' }}>
            <Button size="large" component={Link} href={paths.investors.root}>
              View More
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
}
