// react
import { useEffect, useState } from 'react';
// @mui
import { Typography, CardContent, Grid, Box, Card, CardMedia } from '@mui/material';
// constants
import { paths } from '@/routes/paths';
// hooks
import { useResponsive } from '@/hooks';
// next
import Link from 'next/link';
// components
import { Image } from '@/components/common';
// images
import { noImage } from '@/images';
import TextMaxLine from '@/components/minimal/text-max-line';

export default function AnnouncementCard({ announcement }) {
  const { id, userGroup, shortDescription, title, photo } = announcement;

  const [description, setDescription] = useState(null);

  const isMobile = useResponsive('down', 'md');

  useEffect(() => {
    if (!description) {
      import('html-react-parser').then((res) => {
        setDescription(res.default(shortDescription ?? ' '));
      });
    }
  }, [description, shortDescription]);

  return (
    <Box height="100%" width={{ xs: '240px', md: '275px' }} mx="auto">
      <Box sx={{ height: '100%', p: 1 }}>
        <Card
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '5px',
          }}
        >
          <Link href={`${paths.announcements}/${id}`} legacyBehavior passHref prefetch={false}>
            <a>
              <CardMedia>
                <Image
                  src={photo}
                  width={isMobile ? 227 : 262}
                  height={isMobile ? 119 : 137}
                  onError={function (event) {
                    event.target.src = noImage.src;
                  }}
                  alt="event image"
                  sx={{
                    '&:hover': { cursor: 'pointer' },
                  }}
                />
              </CardMedia>

              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    {userGroup?.title && (
                      <Typography className="eventCardUsername">{userGroup?.title}</Typography>
                    )}
                    <Typography className="eventCardHead">{title}</Typography>
                  </Grid>
                  {description && (
                    <Grid item xs={12}>
                      <TextMaxLine component="div" sx={{ wordBreak: 'break-all' }}>
                        {description}
                      </TextMaxLine>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </a>
          </Link>
        </Card>
      </Box>
    </Box>
  );
}
