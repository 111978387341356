import { Box } from '@mui/material';
import { styled } from '@mui/system';

const SectionHeader = styled('div')({
  fontSize: '25px',
  textAlign: 'center',
  fontWeight: 100,
  color: '#151F2F',
  paddingTop: '2%',
});

const SectionBody = styled(Box)((props) => ({
  backgroundColor: props.backgroundColor ? props.backgroundColor : '#ECECEC',
  height: props.height ? props.height : '40rem',
  width: props.width ? props.width : '100%',
  paddingBottom: '3%',
}));

const SectionContent = styled(Box)((props) => ({
  margintop: props.marginTop ? props.marginTop : '2%',
  marginleft: props.carousel ? '25%' : props.marginLeft ? props.marginLeft : 0,
}));

const BaseSection = (props) => {
  return (
    <>
      <SectionBody
        backgroundColor={props.backgroundColor}
        width={props.width}
        height={props.height}
        {...props.sx}
      >
        {props.header ? <SectionHeader>{props.header.toUpperCase()}</SectionHeader> : null}

        <SectionContent
          carousel={props.carousel}
          marginLeft={props.marginLeft}
          marginTop={props.marginTop}
        >
          {props.children}
        </SectionContent>
      </SectionBody>
    </>
  );
};

export default BaseSection;
