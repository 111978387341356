import { BaseService } from '../BaseService';
import {
  RecentUserGroupAnnouncementsDataDto,
  UserGroupAnnouncementGetDetailMetaResponseDto,
} from './dto';

class UserGroupAnnouncementsService extends BaseService {
  postList(page: number, size: number) {
    return this.post(`list?page=${page}&size=${size}`);
  }
  getRecentAnnouncements() {
    return this.get<RecentUserGroupAnnouncementsDataDto[]>('recent');
  }
  getAnnouncement(id: number) {
    return this.get('' + id);
  }
  getAnnouncementMeta(id: number) {
    return this.get<UserGroupAnnouncementGetDetailMetaResponseDto>(id + '/meta');
  }
  getUnansweredAnnouncementQuestions(announcementId: number) {
    return this.get(`${announcementId}/unanswered-announcement-questions`);
  }
  getComments(announcementId: number, page = 1, size = 10) {
    return this.get(`${announcementId}/comments`, { params: { page, size } });
  }
  postComments(announcementId: number, comment: string) {
    return this.post(`${announcementId}/comments`, { comment });
  }
  putLike(announcementId: number) {
    return this.put(`${announcementId}/like`);
  }
  postSubmitQuestions(announcementId: number, data: any) {
    return this.post(`${announcementId}/submit-questions`, data);
  }
  postSubmit(announcementId: number) {
    return this.post(`${announcementId}/submit`);
  }
  postIntend(announcementId: number) {
    return this.post(`${announcementId}/intend`);
  }
}

export default new UserGroupAnnouncementsService('user-group-announcements');
