import { BaseService } from './BaseService';

class IncubatorService extends BaseService {
  getDetail(userGroupId) {
    return this.get(`${userGroupId}/detail`);
  }
  getExploreIndustryInterests() {
    return this.get(`explore/industry-interests`);
  }

  getExplorePopular(page, size) {
    return this.get(`explore/popular`, { params: { page, size } });
  }
  getExploreNewest(page, size) {
    return this.get(`explore/newest`, { params: { page, size } });
  }
  postSearch(page = 1, size = 10, data) {
    return this.post(`search`, data, { params: { page, size } });
  }
  getDetailQuestions(userGroupId) {
    return this.get(`${userGroupId}/detail/questions`);
  }
  getApplyToIncubator(userGroupId) {
    return this.get(`${userGroupId}/apply-to-incubator`);
  }
  postSubmitToIncubator(userGroupId, data) {
    return this.post(`${userGroupId}/submit-to-incubator`, data);
  }
}

export default new IncubatorService('incubators');
