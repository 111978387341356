// @mui
import { Grid, Container, Typography } from '@mui/material';
// components
import { Loading } from '@/components/common';
// next
import dynamic from 'next/dynamic';
//
const Carousel = dynamic(() => import('./Carousel'), { loading: () => <Loading /> });

export default function HeroSliderSection({ sliders }) {
  return (
    <Container
      component="section"
      maxWidth="100%"
      sx={{
        my: 5,
        width: '100%',
        mx: 0,
      }}
    >
      <Grid container spacing={4} direction="column" alignItems="stretch" justifyContent="center">
        {Boolean(sliders?.length) && (
          <Grid item xs={12} container direction="column" alignItems="center">
            <Carousel sliders={sliders} />
          </Grid>
        )}
        <Grid item xs={12} container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h3" component="h1">
              ScaleupStream where the world meets innovation.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
