import { BaseService } from '../BaseService';
import { InvestorsGetRatesResponseDto, InvestorsGetRatesSummaryResponseDto } from './dto';

class InvestorsService extends BaseService {
  getDetail(userGroupId: number) {
    return this.get(`${userGroupId}/detail`);
  }
  getExplorePopular(page: number, size: number) {
    return this.get(`explore/popular`, { params: { page, size } });
  }
  getExploreRandomInvestors(count = 3) {
    return this.get(`explore/random-investors?count=${count}`);
  }
  getExploreNewest(page: number, size: number) {
    return this.get(`explore/newest`, { params: { page, size } });
  }
  postSearch(page = 1, size = 10, data: any) {
    return this.post(`search`, data, { params: { page, size } });
  }
  getExploreIndustryInterests() {
    return this.get(`explore/industry-interests`);
  }
  postApplyToPitch(userGroupId: number) {
    return this.post(`${userGroupId}/apply-to-pitch`);
  }
  postSubmitQuestions(userGroupId: number, data: any) {
    return this.post(`${userGroupId}/submit-questions`, data);
  }
  postSubmitToPitch(userGroupId: number, data: any) {
    return this.post(`${userGroupId}/submit-to-pitch`, data);
  }
  getDetailQuestions(userGroupId: number) {
    return this.get(`${userGroupId}/detail/questions`);
  }
  getRatesSummary(userGroupId: number) {
    return this.get<InvestorsGetRatesSummaryResponseDto>(`${userGroupId}/rates/summary`);
  }
  postRates(userGroupId: number, data: any) {
    return this.post(`${userGroupId}/rates`, data);
  }
  getRates(userGroupId: number, page = 1, size = 10) {
    return this.get<InvestorsGetRatesResponseDto[]>(`${userGroupId}/rates`, {
      params: {
        page,
        size,
      },
    });
  }
}

export default new InvestorsService('investors');
