// react
import { useCallback, useEffect, useMemo, useState } from 'react';
// @mui
import { Grid, CardContent, Box, Button, CardActions, Rating, Typography } from '@mui/material';
// images
import { defaultInvestor } from '@/images';
// components
import Iconify from '@/components/minimal/iconify';
import TextMaxLine from '@/components/minimal/text-max-line';
import { Image } from '@/components/common';
// framer-motion
import { m, LazyMotion, domAnimation } from 'framer-motion';
// enums
import { STATUS } from '@/enums/status.enum';
import { USER_GROUP_TYPE } from '@/enums/user-group-type.enum';
// constants
import { paths } from '@/routes/paths';
// hooks
import { useAuth, useModal, useResponsive, useSelectedUserGroup } from '@/hooks';
// services
import { IncubatorService, InvestorsService, SMEService, InstitutionService } from '@/services';

const getUserGroupLink = (userGroupType) => {
  switch (userGroupType) {
    case USER_GROUP_TYPE.INVESTOR:
      return paths.investors.root;
    case USER_GROUP_TYPE.INCUBATOR:
      return paths.incubators.root;
    case USER_GROUP_TYPE.SME:
      return paths.smes.root;
    case USER_GROUP_TYPE.INSTITUTION:
      return paths.institutions.root;
  }
};

export default function UserGroupCard({
  footer,
  id,
  image,
  header,
  text,
  userGroupType,
  userGroup,
  rating,
  ratingCount,
  openDetailOnNewTab = false,
  hideActionButton = false,
}) {
  const { user } = useAuth();

  const [description, setDescription] = useState(null);

  const {
    login: { setOpen: setLoginModalOpen },
    applyToPitch: { setOpen: setApplyToPitchModalOpen, setProps: setApplyToPitchModalProps },
    selectStartup: { setOpen: setSelectStartupOpen, setProps: setSelectStartupProps },
    createStartup: { setOpen: setCreateStartupModal },
    calendlyScheduleMeeting: {
      setOpen: setCalendlyScheduleMeetingModalOpen,
      setProps: setCalendlyScheduleMeetingModalProps,
    },
  } = useModal();

  const selectedUserGroup = useSelectedUserGroup();

  useEffect(() => {
    if (!description) {
      import('sanitize-html').then((res) => {
        setDescription(
          res.default(text ?? ' ', {
            allowedTags: [],
            allowedAttributes: {},
          })
        );
      });
    }
  }, [description, text]);

  const isStartupMember = useMemo(
    () =>
      Boolean(
        user?.userGroupMembers?.filter(
          (item) => item.userGroup.userGroupType === USER_GROUP_TYPE.STARTUP
        )?.length > 0
      ),
    [user]
  );

  const handleApplyToPitch = useCallback(async () => {
    if (userGroupType === USER_GROUP_TYPE.INVESTOR) {
      var { success, data = [] } = await InvestorsService.postApplyToPitch(id);
    }
    if (userGroupType === USER_GROUP_TYPE.INCUBATOR) {
      var { success, data = [] } = await IncubatorService.getApplyToIncubator(id);
    }

    if (success) {
      setApplyToPitchModalProps({
        userGroupId: id,
        contentId: null,
        announcementId: null,
        applyToPitchData: data,
        userGroupType,
        dontForward: true,
      });
      setApplyToPitchModalOpen(true);
    }
  }, [id, setApplyToPitchModalOpen, setApplyToPitchModalProps, userGroupType]);

  const handleScheduleSMEMeeting = useCallback(async () => {
    await SMEService.postScheduleMeeting(userGroup?.id);
  }, [userGroup?.id]);

  const handleScheduleInstitutionMeeting = useCallback(async () => {
    await InstitutionService.postScheduleMeeting(userGroup?.id);
  }, [userGroup?.id]);

  const handleUserGroupAction = () => {
    if (userGroupType === USER_GROUP_TYPE.SME) {
      setCalendlyScheduleMeetingModalProps({
        calendlyLink: userGroup?.sme?.calendlyLink,
        contentId: null,
        registered: false,
        onSuccess: handleScheduleSMEMeeting,
      });
      setCalendlyScheduleMeetingModalOpen(true);
    } else if (userGroupType === USER_GROUP_TYPE.INSTITUTION) {
      setCalendlyScheduleMeetingModalProps({
        calendlyLink: userGroup?.institution?.calendlyLink,
        contentId: null,
        registered: false,
        onSuccess: handleScheduleInstitutionMeeting,
      });
      setCalendlyScheduleMeetingModalOpen(true);
    } else if (isStartupMember) {
      if (selectedUserGroup?.userGroupType !== USER_GROUP_TYPE.STARTUP) {
        setSelectStartupProps({
          startups:
            user?.userGroupMembers
              ?.filter(
                (item) =>
                  item?.userGroup?.userGroupType === USER_GROUP_TYPE.STARTUP &&
                  item?.userGroup?.status === STATUS.ACTIVE
              )
              ?.map(({ userGroup }) => userGroup) ?? [],
          onSelect: handleApplyToPitch,
        });
        setSelectStartupOpen(true);
      } else {
        handleApplyToPitch();
      }
    } else {
      setCreateStartupModal(true);
    }
  };

  const getUserGroupActionLabel = useCallback((userGroupType) => {
    switch (userGroupType) {
      case USER_GROUP_TYPE.INVESTOR:
        return 'Apply to Pitch';
      case USER_GROUP_TYPE.INCUBATOR:
        return 'Apply to Incubator';
      case USER_GROUP_TYPE.SME:
      case USER_GROUP_TYPE.INSTITUTION:
        return 'Request Meeting';
    }
  }, []);

  const enterInvestorPage = (id) => {
    window.open(
      `${getUserGroupLink(userGroupType)}/${id}`,
      openDetailOnNewTab ? '_blank' : '_self'
    );
  };

  const isMobile = useResponsive('down', 'md');

  return (
    <Grid
      item
      mx={1}
      sx={{
        width: isMobile ? 290 : 350,
        maxWidth: isMobile ? 290 : 350,
      }}
    >
      <LazyMotion features={domAnimation}>
        <m.div whileHover={{ scale: 1.02 }}>
          <Box className="investorsCard">
            <Grid
              item
              xs={12}
              sx={{
                '&:hover': { cursor: 'pointer' },
              }}
              onClick={() => enterInvestorPage(id)}
            >
              <Image
                src={image || defaultInvestor}
                onError={function (e) {
                  e.target.src = defaultInvestor;
                  e.target.srcset = defaultInvestor.src;
                }}
                alt={header}
                width={isMobile ? 290 : 350}
                height={isMobile ? 75 : 90}
              />
            </Grid>
            <CardContent
              sx={{
                '&:hover': { cursor: 'pointer' },
                boxSizing: 'content-box',
                height: 140,
              }}
              onClick={() => enterInvestorPage(id)}
              className="investorsCardContent"
            >
              <Grid item container xs={12} direction="row" alignItems="center">
                <TextMaxLine gutterBottom fontWeight="bold">
                  {header}
                </TextMaxLine>
              </Grid>
              <Grid item xs={12}>
                {description && (
                  <TextMaxLine sx={{ wordBreak: 'break-all' }}>{description}</TextMaxLine>
                )}
              </Grid>
              {footer && (
                <Grid item xs={12} container alignItems="center" flexDirection="row">
                  <Iconify icon="basil:document-outline" sx={{ width: 24, height: 24 }} />
                  <Typography>{footer}</Typography>
                </Grid>
              )}
              {rating && ratingCount && (
                <Grid
                  item
                  xs={12}
                  mt={1}
                  spacing={1}
                  container
                  alignItems="center"
                  flexDirection="row"
                >
                  <Typography variant="subtitle2" color="primary.main">
                    {rating}
                  </Typography>
                  <Rating readOnly value={rating} precision={0.1} size="small" />
                  <Typography>
                    ({ratingCount} review{ratingCount > 1 ? 's' : ''})
                  </Typography>
                </Grid>
              )}
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              {[
                USER_GROUP_TYPE.INVESTOR,
                USER_GROUP_TYPE.INCUBATOR,
                USER_GROUP_TYPE.SME,
                USER_GROUP_TYPE.INSTITUTION,
              ].includes(userGroupType) &&
                !hideActionButton && (
                  <Button
                    onClick={user ? handleUserGroupAction : () => setLoginModalOpen(true)}
                    variant="contained"
                    disabled={
                      userGroupType === USER_GROUP_TYPE.SME ||
                      userGroupType === USER_GROUP_TYPE.INSTITUTION
                        ? !userGroup?.sme?.calendlyLink && !userGroup?.institution?.calendlyLink
                        : !userGroup?.totalQuestions
                    }
                  >
                    <span className="investorbuilderbuttonstext">
                      {getUserGroupActionLabel(userGroupType)}
                    </span>
                  </Button>
                )}
            </CardActions>
          </Box>
        </m.div>
      </LazyMotion>
    </Grid>
  );
}
