import { BaseService } from '../BaseService';
import { SMEGetRatesResponseDto, SMEGetRatesSummaryResponseDto } from './dto';

class SMEService extends BaseService {
  getDetail(userGroupId: number) {
    return this.get(`${userGroupId}/detail`);
  }
  getExplorePopular(page: number, size: number) {
    return this.get(`explore/popular`, { params: { page, size } });
  }
  getExploreNewest(page: number, size: number) {
    return this.get(`explore/newest`, { params: { page, size } });
  }
  postSearch(page = 1, size = 10, data: any) {
    return this.post(`search`, data, { params: { page, size } });
  }
  getDetailQuestions(userGroupId: number) {
    return this.get(`${userGroupId}/detail/questions`);
  }
  postScheduleMeeting(userGroupId: number) {
    return this.post(`${userGroupId}/schedule-meeting`);
  }
  getRatesSummary(userGroupId: number) {
    return this.get<SMEGetRatesSummaryResponseDto>(`${userGroupId}/rates/summary`);
  }
  postRates(userGroupId: number, data: any) {
    return this.post(`${userGroupId}/rates`, data);
  }
  getRates(userGroupId: number, page = 1, size = 10) {
    return this.get<SMEGetRatesResponseDto[]>(`${userGroupId}/rates`, {
      params: {
        page,
        size,
      },
    });
  }
}

export default new SMEService('smes');
